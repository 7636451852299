import Header from "../components/Header"


const CVs=()=>{
    return(
      <div className="main-layout">
         <header>
            <div className="header">
               <div className="white_bg">
                  <Header/>
                  <section className="banner_main">
                        <div className="about">
                           <div className="container">
                              <div className="row d_flex">
                                 <div className="col-md-12 col-lg-12">
                                    <div className="titlepage">
                                       <h2> <span className="yellow"></span><br/>Coming Soon !</h2>
                                       <br/><br/><br/><br/><br/><br/><br/>
                                       
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                  </section>
               </div>
            </div>
         </header>
      </div>
    )
}
export default CVs;