import Header from "../components/Header"
import Contact from "../components/Contact";
import Infocall from "../components/Infocall";
import About from "../components/About";
import '../assets/css/infocall.css';



const ContactUs=()=>{
    return(
      <div className="main-layout">
         <header>
            <div className="header">
               <div className="white_bg">
               <Header/>
               <div className="about">
               <div className="container">
               <div className="row d_flex"></div>
                     <div className="col-md-12 col-lg-7">
                        <div className="titlepage">
                        <h2> <span className="yellow">contact us</span></h2>
                        </div>
                     </div>
               </div>
               </div>
               <Contact/>
               <Infocall/>
               </div>
            </div>
         </header>
     </div>
    )
}

export default ContactUs;