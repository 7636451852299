import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-XQKWSJZ9G9');
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.send(window.location.pathname, {hitType: "pageview"});
};
