import React from 'react';
import '../assets/css/ourteam.css';
import aiman from "../assets/images/aimht.jpg"
// import '../assets/css/thanksto.css'; // Assuming this file exists


const Ourteam = () => {
  return (
    <section className="team-section">
    <h2 className="team-section-heading">Team</h2>
    <div className="container text-center team-container">
      <hr />
      <div className="row justify-content-center">
        <div className="col-md-3 col-sm-6">
          <div className="our-team">
            <div className="pic">
              <img src={aiman} alt="aiman" />
            </div>
            <h3 className="title">Aiman MOUHAT</h3>
            <span className="post">FOUNDER & OWNER</span>
            <ul className="social">
              <li><a href="https://github.com/AI-MHT" className="fa fa-github"></a></li>
              <li><a href="https://ma.linkedin.com/in/aiman-mouhat-354a9a206" className="fa fa-linkedin"></a></li>
              <li><a href="https://www.instagram.com/aiman_mht/" className="fa fa-instagram"></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Ourteam;
