import React from 'react';
import '../assets/css/thanksto.css';
import MohamedBenHamouImage from '../assets/images/simo.png';
import KhalilElJoulaliImage from '../assets/images/khalil.png';
import YoussefElHousniImage from '../assets/images/housni.png';
import IkramZouheirImage from '../assets/images/ikram.jpg';
import FarouqEssadikiImage from '../assets/images/farouq.jpg';

const Thanksto = () => {
  return (
    <section className="team-section">
      <h2 className="team-section-heading">Thanks To</h2>
      <div className="team-container">
        <div className="team-profile">
          <a href="https://www.linkedin.com/in/mohamed-ben-hamou-134981220/" target="_blank" rel="noopener noreferrer">
            <img src={MohamedBenHamouImage} alt="Mohamed BEN HAMOU"/>
            <span className="team-name">Mohamed BEN HAMOU</span>
          </a>
        </div>
        <br/>
        <div className="team-profile">
          <a href="https://www.linkedin.com/in/khalil-el-joulali-6925931b5/" target="_blank" rel="noopener noreferrer">
            <img src={KhalilElJoulaliImage} alt="Khalil EL JOULALI"/>
            <span className="team-name">Khalil EL JOULALI</span>
          </a>
        </div>
        <div className="team-profile">
          <a href="https://www.linkedin.com/in/youssef-el-housni/" target="_blank" rel="noopener noreferrer">
            <img src={YoussefElHousniImage} alt="Youssef EL HOUSNI"/>
            <span className="team-name">Youssef EL HOUSNI</span>
          </a>
        </div>
        <div className="team-profile">
          <a href="https://www.linkedin.com/in/ikram-zouheir-565722222/" target="_blank" rel="noopener noreferrer">
            <img src={IkramZouheirImage} alt="Ikram ZOUHEIR"/>
            <span className="team-name">Ikram ZOUHEIR</span>
          </a>
        </div>
        <div className="team-profile">
          <a href="https://www.linkedin.com/in/farouq-essadiki-75703b220/" target="_blank" rel="noopener noreferrer">
            <img src={FarouqEssadikiImage} alt="Farouq ESSADIKI"/>
            <span className="team-name">Farouq ESSADIKI</span>
          </a>
        </div> 
      </div>
    </section>
  );
};

export default Thanksto;
