import React from 'react';
import "../assets/css/coming.css";
import logo from '../assets/images/Logo.png'; // Adjust the path to your logo file

class MusicPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false
    };
    this.audioPlayer = React.createRef();
    this.togglePlay = this.togglePlay.bind(this);
  }

  togglePlay() {
    const audio = this.audioPlayer.current;
    if (this.state.isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    this.setState({ isPlaying: !this.state.isPlaying });
  }

  render() {
    return (
      <div className="container ct ac">
        <img src={logo} alt="Logo" />
        <br/><br/><br/><br/>
        <h2>We Are Coming Soon.</h2>
        <br/>
        <audio ref={this.audioPlayer}>
          <source src="https://raw.githubusercontent.com/AI-MHT/Portfolio/main/heart.mp3" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        {/* Play button */}
        <button onClick={this.togglePlay}>
          {this.state.isPlaying ? 'stop' : 'feeling'}
        </button>
      </div>
    );
  }
}

export default MusicPlayer;
