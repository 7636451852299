import React from "react";
import '../assets/css/bootstrap.min.css';
import '../assets/css/header.css';
import '../assets/css/style.css';
import '../assets/css/stylec.css';
import '../assets/css/footer.css';
import '../assets/css/testimonial.css';
import '../assets/css/infocall.css';


// import {Link} from "react-router-dom"
import Header from "../components/Header"
import Event from "../components/Event"
import StudentFeed from "../components/StudentFeed";
import Abouthome from "../components/Abouthome";
import Sponsor from "../components/Sponsor";

//import images

import aboutus from "../assets/images/about.png"
import Logo from "../assets/images/Loddgo.png"

import ensemsport from"../assets/images/ensemsport.jpg"
import ramadanensem from "../assets/images/ramadan.png"
const Home=()=>{
    return(
      <div className="main-layout">

         <header>
           <div className="header">
              <div className="white_bg">
              <Header/>
              <section className="banner_main">
                  <div id="banner1" className="carousel slide" data-ride="carousel">
                  
                  <div className="carousel-inner">

                     <Event
                        active={false}
                        imageUrl={ensemsport}
                        altText="BAN"
                        title="ENSEMSPORT"
                        subtitle="Tournoi Mensuel du Ramadan"
                        description={<>Le comité Ensem Sport est ravi de vous annoncer que nous organisons à nouveau le tournoi mensuel du Ramadan cette année ! 🌙🏆 Préparez-vous à vivre des moments de compétition palpitants et de camaraderie pendant ce mois sacré ! 💥 Les inscriptions sont maintenant ouvertes ! Remplissez dès maintenant le formulaire dédié au tournoi du Ramadan pour inscrire votre équipe et rejoindre l’aventure ! 📝 Nous avons hâte de partager cette expérience sportive unique avec vous tous ! 🤩 <br/><a href="https://docs.google.com/forms/d/e/1FAIpQLSdDnKu9-fiK6AXdxJxggNiHWCOhZmHaqW-AIX0mxnJq5Ridag/viewform?usp=pp_url"><strong>Inscrivez-vous maintenant<strong/></strong></a>.</>}
                     />
                     <Event
                        active={false}
                        imageUrl={ramadanensem}
                        altText="BAN"
                        title="ENSEMSDOCS"
                        subtitle="Ramadan Mubarak"
                        description="ENSEMDOCS vous souhaite Ramadan Mubarak ! Que ce mois sacré soit rempli de bénédictions, de paix et de joie pour vous et vos proches."
                     />
                     <Event
                        active={true}
                        imageUrl={Logo}
                        altText="BAN"
                        title="Word Of Creator & Owner"
                        subtitle="ENSEMDOCS"
                        description="At ENSEM, we believe in the power of knowledge sharing and collaboration. Our website serves as a bridge between students, helping them showcase their achievements and experiences during their internships. By providing a space to share internship reports and CVs, we strive to create a supportive community where students can inspire one another, learn from each other's successes, and support their career successes, and support their career"
                     />
                    
                     
                  </div>
                  <a className="carousel-control-prev" href="#banner1" role="button" data-slide="prev">
                     <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </a>
                  <a className="carousel-control-next" href="#banner1" role="button" data-slide="next">
                     <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </a>
                  </div>
               </section>
              </div>
              {/* <Sponsor/> */}
           </div>

        </header>
        <Abouthome
          imageUrl={aboutus}
          altText="ABOUT"
          title="ABOUT US"
          description="My website is designed to connect students from ENSEM and beyond, enabling them to share their internship journey with fellow students, professionals, and potential employers. I understand the significance of internships in shaping a student's professional growth and the importance of access to valuable resources. Therefore, I have created this platform to make it easier for students to showcase their skills, projects, and accomplishments to a wider audience."/>
         {/* <StudentFeed/>              */}
     </div>
    )
}

export default Home;